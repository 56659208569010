<template>
  <div class="account-number">
    <div class="account-left">
      <div>
        <el-input
          size="small"
          class="input-bottom"
          v-model="searchForm.userLastName"
          placeholder="姓"
          clearable
        ></el-input>
        <el-input
          size="small"
          class="input-bottom"
          v-model="searchForm.userFirstName"
          placeholder="名"
          clearable
        ></el-input>

        <el-input
          size="small"
          class="input-bottom"
          v-model.number="searchForm.userId"
          placeholder="用户id"
          clearable
        ></el-input>
        <el-input
          size="small"
          class="input-bottom"
          placeholder="用户名"
          v-model="searchForm.username"
          clearable
        ></el-input>
        <el-button
          type="primary"
          size="mini"
          style="width: 100%; margin-top: 10px; border-radius: 16px"
          @click="searchFun"
          :loading="loading"
        >
          搜 索
        </el-button>
      </div>
      <logPage
        title="检索记录"
        ref="searchIndex2Log"
        height="calc(100% - 205px)"
        :logList="logList"
        showType="3"
        @selectLog="selectLog"
      />
    </div>
    <div
      class="account-right"
      :style="{ background: list.length == 0 ? 'white' : '' }"
      v-loading="loading"
    >
      <div
        class="right-box"
        id="t-scroll-userSearch"
        v-infinite-scroll="getTelegramUsersSearch"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div class="item-box" v-for="(item, i) in list" :key="i">
          <div class="top">
            <img
              :src="
                item.user_photo
                  ? 'data:image/png;base64,' + item.user_photo
                  : require('@/assets/imgs/p.png')
              "
              alt=""
            />
            <div class="top-name">
              <div>{{ item.username }}</div>
              <div>ID:{{ item.userId }}</div>
            </div>
          </div>
          <div class="content">
            <el-descriptions :column="1" :colon="false">
              <el-descriptions-item label="姓">
                {{ item.userLastName }}
              </el-descriptions-item>
              <el-descriptions-item label="名">
                {{ item.userFirstName }}
              </el-descriptions-item>
              <el-descriptions-item
                labelStyle="width: 20px;fontSize:20px;color;black"
              >
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                </template>
                <span class="phone" @click="userDetail(2, item.userId)">
                  手机号查询
                </span>
              </el-descriptions-item>
              <el-descriptions-item
                labelStyle="width: 20px;fontSize:20px;color;black"
                v-if="userInfo.isYs == 0"
              >
                <template slot="label">
                  <i class="el-icon-map-location"></i>
                </template>
                <span class="phone" @click="userDetail(1, item.userId)">
                  归属地
                </span>
              </el-descriptions-item>

              <el-descriptions-item v-if="item.updateTime" label="更新时间">
                {{ item.updateTime }}
              </el-descriptions-item>
              <el-descriptions-item v-else>
                <div style="height:20px"></div>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="foot">
            <span class="el-icon-document" @click="userRetrieval(item.userId)">
              开始检索
            </span>
          </div>
        </div>
        <li v-if="dataLoading">
          <span v-if="list.length > 0">加载中...</span>
        </li>
        <li v-else>
          <span v-if="list.length > 0">没有更多了...</span>
        </li>
      </div>
      <noData v-show="list.length == 0" />
    </div>
    <userDetail
      ref="userDetailDialog"
      :placeVisible="placeVisible"
      @closePlace="closePlace"
    />
  </div>
</template>

<script>
import { telegramUsersSearch } from '../../api/retrieval'
import { getLocalStorageFun, localStorageFun } from '@/plugins/logFunction'
import { scrollTopFun } from '@/plugins/logFunction'
export default {
  name: 'SearchUsers',
  data() {
    return {
      searchForm: {
        userFirstName: '',
        userId: '',
        userLastName: '',
        username: ''
      },
      listScrollTop: 0,
      params: {},
      current: 1,
      limit: 50,
      list: [],
      logList: [],
      dataLoading: true, //是否还有更多数据
      loading: false, //加载状态
      scrollDisabled: false,
      placeVisible: false //手机、归属地详情弹框
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  created() {
    // this.getTelegramUsersSearch()
    this.logList = getLocalStorageFun('accountNumberLog')
  },
  activated() {
    scrollTopFun('t-scroll-userSearch', 'set', this.listScrollTop)
  },
  deactivated() {
    this.listScrollTop = scrollTopFun('t-scroll-userSearch', 'get')
  },
  methods: {
    getTelegramUsersSearch() {
      this.loading = true
      this.scrollDisabled = true
      this.params = Object.assign({}, this.searchForm)
      return new Promise((resolver, reject) => {
        telegramUsersSearch(this.current, this.limit, this.params).then(
          data => {
            this.loading = false
            if (data.items.length > 0) {
              this.list = this.list.concat(data.items)
              this.current++
              this.scrollDisabled = false
              if (data.items.length < this.limit) {
                this.dataLoading = false
              }
            } else {
              this.dataLoading = false
            }
            resolver()
          }
        )
      })
    },
    searchFun() {
      if (
        this.searchForm.userId &&
        this.searchForm.userId !== '' &&
        this.searchForm.userId !== null
      ) {
        if (typeof this.searchForm.userId !== 'number') {
          this.$message.warning('用户id 为数字！')
          return
        }
      }

      this.dataLoading = true
      this.scrollDisabled = true
      this.$refs.searchIndex2Log.selectIndex = null
      this.current = 1
      this.list = []
      this.getTelegramUsersSearch().then(res => {
        this.scrollDisabled = false
      })

      localStorageFun('accountNumberLog', this.searchForm)
      this.logList = getLocalStorageFun('accountNumberLog')
    },
    selectLog(row) {
      if (this.loading) {
        // this.$refs.searchIndex2Log.selectIndex = null
        this.$message.warning('数据检索中，请稍后...')
        return
      }
      this.searchForm = Object.assign({}, row)
      this.dataLoading = true
      this.scrollDisabled = true
      this.current = 1
      this.list = []
      this.getTelegramUsersSearch().then(res => {
        this.scrollDisabled = false
      })
    },
    userDetail(type, uid) {
      let that = this
      this.$confirm(
        `继续查询将消耗${this.userInfo.isYs == 0 ? '积分' : '次数'}，是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$refs.userDetailDialog.type = type
          this.$refs.userDetailDialog.uid = uid
          this.$refs.userDetailDialog.getBlastUid().then(data => {
            that.placeVisible = true
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 关闭手机、归属地弹框
    closePlace() {
      this.placeVisible = false
    },
    // 人员检索
    userRetrieval(id) {
      this.$router.push('/user/detail/' + id)
    }
  }
}
</script>

<style lang="scss" scoped>
.account-number {
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  .account-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    .input-bottom {
      margin-bottom: 10px;
    }
  }
  .account-right {
    width: calc(100% - 270px);
    height: 100%;

    // border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding-left: 5px;
    position: relative;
    // overflow: hidden;
    .right-box {
      width: calc(100% + 10px);
      height: 100%;
      overflow: auto;
      // display: flex;
      // flex-wrap: wrap;
      .item-box {
        display: inline-block;
        width: calc(100% / 5 - 10px);
        margin: 0 10px 5px 0;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        padding: 10px;
        padding-bottom: 0;
        overflow: hidden;

        .top {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            align-self: center;
          }
          .top-name {
            margin-left: 10px;
            align-self: center;
            white-space: nowrap;
            width: calc(100% - 50px);

            & > div:first {
              font-size: 14px;
              font-weight: 600;
              color: #1a1a1b;
              word-wrap: break-word;
            }
            & > div:last-child {
              font-size: 12px;
              font-weight: 400;
              color: #555555;
              line-height: 20px;
            }
          }
        }
        .content {
          white-space: nowrap;
          margin-top: 10px;
          .phone {
            text-decoration: underline;
            cursor: pointer;
            &:hover {
              color: rgb(0, 153, 255);
            }
          }
        }
        .foot {
          width: 100%;
          height: 40px;
          text-align: center;
          border-top: 1px solid #f2f4f8;
          span {
            line-height: 40px;
            font-size: 14px;
            font-weight: 600;
            color: #080808;
            cursor: pointer;
            user-select: none;
            line-height: 40px;
            &:hover {
              color: rgb(0, 153, 255);
            }
          }
        }
      }
    }
    li {
      list-style: none;
      text-align: center;
      color: #555555;
      font-size: 12px;
    }
  }
}
</style>
