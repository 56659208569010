/*
params  localStorageName   localStorage存储名称
params form 查询表单

*/

function localStorageFun(localStorageName, form) {
  let arrString = localStorage.getItem(localStorageName)
  let arr = JSON.parse(arrString)
  console.log(form)
  if (arr && Array.isArray(arr)) {
    if (arr.length < 10) {
      if (isEmpty(form)) {
        arr.unshift(form)
      }
    } else {
      if (isEmpty(form)) {
        arr.shift()
        arr.unshift(form)
      }
    }
  } else {
    arr = []
    if (isEmpty(form)) {
      arr.unshift(form)
    }
  }
  localStorage.setItem(localStorageName, JSON.stringify(arr))
}
/*
params  localStorageName   localStorage存储名称
*/

function getLocalStorageFun(localStorageName) {
  let arrString = localStorage.getItem(localStorageName)
  let arr = JSON.parse(arrString)
  if (arr && Array.isArray(arr)) {
    return arr
  } else {
    return []
  }
}
function isEmpty(obj) {
  let arr = []
  for (let i in obj) {
    // if (obj[i] && obj[i].trim() !== '') {
    //   arr.push(obj[i])
    // }
    if (obj[i] && obj[i].trim() !== '') {
      arr.push(obj[i])
    }
  }
  if (arr.length == 0) {
    return false
  } else {
    return true
  }
}
function scrollDomFun(childDom, parentDom) {
  // 滚动
  let c_dom = document.getElementById(childDom)
  let p_dom = document.getElementById(parentDom)
  p_dom.scrollTop = c_dom.offsetTop
}
function scrollTopFun(dom, type, topNumber) {
  let domHtml = document.getElementById(dom)
  if (!domHtml) return console.log('dom不存在')
  if (type === 'get') {
    return domHtml.scrollTop
  } else if (type === 'set') {
    domHtml.scrollTop = topNumber
  }
}
export { localStorageFun, getLocalStorageFun, scrollDomFun, scrollTopFun }
